/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */

export default {
    ['PLT']: 'Pallet',
    ['BAG']: 'Bag',
    ['BALE']: 'Bale',
    ['BOX']: 'Box',
    ['BUCKET']: 'Bucket',
    ['PAIL']: 'Pail',
    ['BUNDLE']: 'Bundle',
    ['CAN']: 'Can',
    ['CARTON']: 'Carton',
    ['CASE']: 'Case',
    ['COIL']: 'Coil',
    ['CRATE']: 'Crate',
    ['CYLINDER']: 'Cylinder',
    ['DRUM']: 'Drum',
    ['PIECES']: 'Pieces',
    ['REEL']: 'Reel',
    ['ROLL']: 'Roll',
    ['TUBE']: 'Tube',
    ['TOTE']: 'Tote',
    ['OTHER']: 'Other Freight services',
    ['FORKLIFT']: 'Forklift',
    ['PARCEL']: 'Parcel',
    ['PAK']: 'Pak',
    ['ENVELOPE']: 'Envelope',
    ['YOUR_PACKAGING']: 'Your Packaging',
    ['EXPRESS_BOX']: 'Express Box',

    // Descriptions to come
    ['Description for PLT']: 'Pallet description',
    ['Description for BAG']: 'A "Bag" refers to a flexible container made of fabric or plastic used for storing, protecting, and transporting goods or materials. Bags are used for packaging a wide range of products, including grains, seeds, flour, cement, chemicals, and consumer goods.',
    ['Description for BALE']: 'A Bale refers to a large, tightly compressed bundle of goods or materials, often wrapped in materials such as wire, rope, or plastic for transport or storage. Bales are commonly used for shipping bulk commodities such as cotton, hay, straw, paper, and recyclable materials.',
    ['Description for BOX']: 'A "Box" is a rigid, rectangular container typically made of materials such as cardboard, wood, or plastic, used for packaging and transporting goods. It offers protection, containment, and efficiency in storage and transit operations. The term "Box" is commonly used interchangeably with "Carton" in the context of packaging and shipping.',
    ['Description for BUCKET']: 'A bucket is typically a cylindrical or conical container with a handle used for carrying or holding liquids, materials, or small items. It\'s commonly made of metal, plastic, or other durable materials. Generally taller and deeper than a Pail and open-top, without a lid.',
    ['Description for PAIL']: 'A pail is a small cylindrical container with a handle, commonly used for transporting or storing liquids, chemicals, or materials. It resembles a bucket but is smaller in size and capacity. Pails are usually made of metal or plastic and may include lids to secure their contents during transportation.',
    ['Description for BUNDLE']: 'A "Bundle" refers to a grouping of items that are packaged or tied together for convenience, efficiency, and ease of handling during transportation or storage.',
    ['Description for CAN']: 'Can description',
    ['Description for CARTON']: 'The term "carton" is commonly used interchangeably with "box" in the context of packaging and shipping. A "Carton" is defined as a type of packaging container typically made of cardboard or corrugated material.',
    ['Description for CASE']: 'Case description',
    ['Description for COIL']: 'A "Coil" refers to a large, heavy cylindrical roll of material, such as steel, aluminum, or other metals. Coils are often secured using specialized equipment, such as coil racks, chains, or straps, to prevent them from shifting or rolling during transport. ',
    ['Description for CRATE']: 'A "Crate" typically refers to a wooden or metal container used for transporting or storing goods. Crates are often constructed with slatted sides or a solid frame and are designed to provide protection for the contents while allowing for ventilation.',
    ['Description for CYLINDER']: 'Cylinder description',
    ['Description for DRUM']: 'A "Drum" refers to a cylindrical container used for storing and transporting liquids, powders, or other materials. Drums may have removable or fixed lids, and they are often designed to be sealed tightly to prevent leakage or spillage during transportation.',
    ['Description for PIECES']: 'Pieces description',
    ['Description for REEL']: 'A "Reel" is commonly used for materials that can be wound and unwound easily, such as electrical cables, fiber optic cables, hoses, and paper products like newsprint or packaging materials. Reels may vary in size depending on the length and diameter of the material being wound.',
    ['Description for ROLL']: 'A "Roll" typically refers to a cylindrical form of material that is wound around a central core or tube',
    ['Description for TUBE']: 'A "Tube" typically refers to a cylindrical hollow structure made of materials such as cardboard, plastic, or metal. Tubes are commonly used for packaging and transporting items that are long, slender, or delicate, such as posters, documents, or architectural drawings.',
    ['Description for TOTE']: 'A "Tote" refers to a large container or bin used for transporting goods. They are often made of durable materials such as plastic or metal and are designed to be stackable and easily moved using forklifts or other material handling equipment.',
    ['Description for OTHER']: 'Other description',
    ['Description for FORKLIFT']: 'A forklift  is equipped with forks or prongs that can be raised and lowered hydraulically to lift and carry loads. They are commonly used in warehouses, distribution centers, and manufacturing facilities for loading and unloading trucks, stacking and storing goods in racks, and moving materials around the facility.',
    ['Description for PARCEL']: 'Parcel description',
    ['Description for PAK']: 'Packaging options provided and specific to each carriers.',
    ['Description for ENVELOPE']: 'Documents and letters shipments. Shipper\'s and carrier\'s own packaging.',
    ['Description for YOUR_PACKAGING']: 'Shipper\'s own package or small bundle of items.',
    ['Description for EXPRESS_BOX']: 'Packaging options provided and specific to each carriers.',
    ['Description for Carrier Package']: 'Packaging options provided and specific to each carriers.',
}
