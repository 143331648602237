/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
import en from './PackageTypeAndDescription.i18n.en'
import { Translations } from '@lazr/i18n'

const PackageTypeAndDescriptionTranslationFr: Translations<typeof en> = {
    ['PLT']: 'Palette',
    ['BAG']: 'Sac',
    ['BALE']: 'Balle',
    ['BOX']: 'Boîte',
    ['BUCKET']: 'Seau',
    ['PAIL']: 'Pail',
    ['BUNDLE']: 'Paquet',
    ['CAN']: 'Can',
    ['CARTON']: 'Carton',
    ['CASE']: 'Caisse',
    ['COIL']: 'Bobine',
    ['CRATE']: 'Crate',
    ['CYLINDER']: 'Cylindre',
    ['DRUM']: 'Fût',
    ['PIECES']: 'Pièces',
    ['REEL']: 'Reel',
    ['ROLL']: 'Rouleau',
    ['TUBE']: 'Tube',
    ['TOTE']: 'Bac',
    ['OTHER']: 'Autres services de fret',
    ['FORKLIFT']: 'Chariot élévateur',
    ['PARCEL']: 'Colis',
    ['PAK']: 'Pak',
    ['ENVELOPE']: 'Enveloppe',
    ['YOUR_PACKAGING']: 'Votre emballage',
    ['EXPRESS_BOX']: 'Boîte express',

    // Descriptions à recevoir
    ['Description for PLT']:'Description de palette',
    ['Description for BAG']:'Un "Sac" fait référence à un conteneur flexible fait de tissu ou de plastique utilisé pour stocker, protéger et transporter des marchandises ou des matériaux. Les sacs sont utilisés pour emballer une large gamme de produits, y compris des grains, des semences, de la farine, du ciment, des produits chimiques et des biens de consommation.',
    ['Description for BALE']:'Une "Botte" fait référence à un gros paquet de marchandises ou de matériaux étroitement comprimés, souvent enveloppés dans des matériaux tels que le fil métallique, la corde ou le plastique pour le transport ou le stockage. Les bottes ou balles sont couramment utilisées pour expédier des marchandises en vrac telles que le coton, le foin, la paille, le papier et les matériaux recyclables.',
    ['Description for BOX']: 'Un « Boîte » est un conteneur rigide et rectangulaire, généralement fabriqué à partir de matériaux tels que le carton, le bois ou le plastique, utilisé pour l\'emballage et le transport de marchandises. Il offre protection, confinement et efficacité dans les opérations de stockage et de transit. Le terme "Boîte" est couramment utilisé de manière interchangeable avec "Carton" dans le contexte de l\'emballage et de l\'expédition.',
    ['Description for BUCKET']:'Une chaudière est généralement un conteneur cylindrique ou conique avec une poignée utilisé pour transporter ou contenir des liquides, des matériaux ou de petits objets. Elle est généralement fabriqués en métal, en plastique ou d\'autres matériaux durables. Généralement plus grand et plus profond qu\'un sceau et sans couvercle.',
    ['Description for PAIL']:'Un seau est un petit conteneur cylindrique avec une poignée, couramment utilisé pour transporter ou stocker des liquides, des produits chimiques ou des matériaux. Il ressemble à un seau mais est plus petit en taille et en capacité. Les seaux sont généralement fabriqués en métal ou en plastique et peuvent inclure des couvercles pour sécuriser leur contenu pendant le transport.',
    ['Description for BUNDLE']:'Un "Lot" fait référence à un regroupement d\'articles qui sont emballés ou attachés ensemble pour plus de commodité, d\'efficacité et de facilité de manipulation lors du transport ou du stockage.',
    ['Description for CAN']:'Description de can',
    ['Description for CARTON']:'Le terme "carton" est couramment utilisé de manière interchangeable avec "boîte" dans le contexte de l\'emballage et de l\'expédition. Un "carton" est défini comme un type de conteneur d\'emballage généralement fabriqué en carton ou en matériau ondulé.',
    ['Description for CASE']:'Description de caisse',
    ['Description for COIL']:'Une "Bobine" fait référence à un grand rouleau cylindrique et lourd de matériau, tel que l\'acier, l\'aluminium ou d\'autres métaux. Les bobines sont souvent sécurisées à l\'aide d\'équipements spécialisés, tels que des supports de bobine, des chaînes ou des sangles, pour les empêcher de se déplacer ou de rouler pendant le transport.',
    ['Description for CRATE']:'Un "Crate" fait généralement référence à un conteneur en bois ou en métal utilisé pour transporter ou stocker des marchandises. Les caisses sont souvent construites avec des côtés à lattes ou un cadre solide et sont conçues pour protéger le contenu tout en permettant la ventilation.',
    ['Description for CYLINDER']:'Description de cylindre',
    ['Description for DRUM']:'Un "Tonneau" désigne un conteneur cylindrique utilisé pour stocker et transporter des liquides, des poudres ou d\'autres matériaux. Les tonneaux peuvent avoir des couvercles amovibles ou fixes, et ils sont souvent conçus pour être étroitement scellés afin d\'éviter les fuites ou les déversements pendant le transport.',
    ['Description for PIECES']:'Description de pièces',
    ['Description for REEL']:'Un "Bobineau" est couramment utilisé pour les matériaux qui peuvent être enroulés et déroulés facilement, tels que les câbles électriques, les câbles à fibres optiques, les tuyaux et les produits en papier comme le papier journal ou les matériaux d\'emballage. Les bobineaux peuvent varier en taille en fonction de la longueur et du diamètre du matériau enroulé.',
    ['Description for ROLL']:'Un "rouleau" fait généralement référence à une forme cylindrique de matériau enroulée autour d\'un noyau central ou d\'un tube.',
    ['Description for TUBE']:'Un "Tube" fait généralement référence à une structure creuse cylindrique fabriquée à partir de matériaux tels que le carton, le plastique ou le métal. Les tubes sont couramment utilisés pour emballer et transporter des articles longs, minces ou délicats, tels que des affiches, des documents ou des dessins architecturaux.',
    ['Description for TOTE']:'Une "Tote" fait référence à un grand conteneur ou bac utilisé pour transporter des marchandises. Ils sont souvent fabriqués à partir de matériaux durables tels que le plastique ou le métal et sont conçus pour être empilables et facilement déplacés à l\'aide de chariots élévateurs ou d\'autres équipements de manutention.',
    ['Description for OTHER']:'Description de autre',
    ['Description for FORKLIFT']:'Un chariot élévateur est équipé de fourches ou de dents qui peuvent être levées et abaissées hydrauliquement pour soulever et transporter des charges. Ils sont couramment utilisés dans les entrepôts, les centres de distribution et les installations de fabrication pour charger et décharger les camions, empiler et stocker les marchandises dans les rayonnages, et déplacer les matériaux dans l\'installation.',
    ['Description for PARCEL']: 'Description de colis',
    ['Description for PAK']: 'Options d\'emballage spécifiques à chaque transporteur et fournies par eux.',
    ['Description for ENVELOPE']:'Envois de documents et de lettres. Emballage fourni par l\'expéditeur ou le transporteur.',
    ['Description for YOUR_PACKAGING']: 'Colis ou petit ensemble d\'articles avec emballage propre à l\'expéditeur.',
    ['Description for EXPRESS_BOX']: 'Options d\'emballage spécifiques à chaque transporteur et fournies par eux.',
    ['Description for Carrier Package']: 'Options d\'emballage spécifiques à chaque transporteur et fournies par eux.',
}

export default PackageTypeAndDescriptionTranslationFr
