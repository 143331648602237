/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable max-len */
import isoCountries from 'i18n-iso-countries'
import isoCountriesEn from 'i18n-iso-countries/langs/en.json'
import moment from 'moment'
import mtz from 'moment-timezone'

import {
    type AccessorialLocationType,
    type AccessorialType,
    AddressType,
    BillingChargeType,
    Country,
    Currency,
    DensityUnit,
    DimensionUnit,
    EquipmentType,
    ImportExportType,
    InvoiceCsvStatus,
    type PendingPriceReviewStatus,
    Provider,type ShipmentDocumentType,
    type ShipmentIdentifierSource,
    type ShipmentIdentifierType,
    TariffType,
    TransportType,
    VolumeUnit,
    WeightUnit,
    TrackingUpdateStatus,
    ShipmentStatus,
    RfqStatus,
    TrackingUpdateType,
    TrackingUpdateStatusReason,
    TrackingUpdateStopType,
    TrackingUpdateExceptionSummary,
    TrackingUpdateExceptionDetail,
} from '@lazr/enums'

import convert from 'convert-units'
import { isArray, isNumber, remove, uniqBy } from 'lodash'
import { PDFDocument } from 'pdf-lib'
import { Builder, type BuilderOptions, parseStringPromise as parseXmlString, processors } from 'xml2js'
import {
    DEFAULT_ACCESSORIALS,
    DOCK_DEL_ACCESSORIAL,
    DOCK_PU_ACCESSORIAL,
    FREIGHT_SERVICES,
    LIFTGATE_ACCESSORIALS,
    MAILING_SERVICES,
    RESIDENTIAL_DEL_ACCESSORIAL,
    RESIDENTIAL_PU_ACCESSORIAL,
    RESIDENTIAL_RELATED_ACCESSORIALS,
} from './constants'
import type { StateAndProvinces } from './taxCalculator'

isoCountries.registerLocale(isoCountriesEn)

export type phoneAndExtension = {
    number: string
    extension: string
}

interface Accessorial {
    accessorial: AccessorialInfo
    isRemovable: boolean
}

interface AccessorialInfo {
    id: string
    code: string
    name: string
    type: AccessorialType
    transportTypes: TransportType[]
    locationType: AccessorialLocationType
    forceCustomQuote: boolean
}


export const chunks = <T>(array: T[], chunkSize: number): T[][] => {
    const result = []

    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize))
    }

    return result
}

export const formatCurrency = (
    value: number,
    currency: string,
    locale = navigator.language,
): string => new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
}).format(value)


export const formatNumber = (
    value: number,
    min: number,
    max: number,
    locale = navigator.language,
): string =>
    new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: min,
        maximumFractionDigits: max,
    }).format(value)

export const getRandomNumber = (length: number): string => {
    let result = ''
    const characters = '123456789'
    const charactersLength = characters.length

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        )
    }

    return result
}

export const getRandomString = (length: number): string => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const charactersLength = characters.length

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength),
        )
    }

    return result
}

export const getStringFromNumber = (number: number): string => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const charIndex = number % alphabet.length
    const quotient = number / alphabet.length

    if (quotient >= 1) {
        return alphabet.charAt(charIndex) + getStringFromNumber(quotient)
    }
    return alphabet.charAt(charIndex)
}

export const capitalizeWords = (words: string): string => {
    const wordList = words.split(' ')

    const capitalizedWordList = wordList.map(
        (word) =>
            word.substring(0, 1).toUpperCase() +
            word.substring(1, word.length).toLowerCase(),
    )

    return capitalizedWordList.join(' ')
}

export const toSentenseCase = (words: string): string => {
    const wordList = words.split(' ')

    const firstWord = wordList[0].substring(0, 1).toUpperCase() +
        wordList[0].substring(1, wordList[0].length).toLowerCase()

    const newWordsList = [ firstWord ]
    const modifiedWordList = wordList.slice(1).map(
        (word) =>
            word.toLowerCase(),
    )
    const newWordListConcatenated = newWordsList.concat(modifiedWordList)

    return newWordListConcatenated.join(' ')
}

export const formatTransitTime = (
    transitDaysMin?: number,
    transitDaysMax?: number,
): string => {
    const transitTimeArray: string[] = []

    if (transitDaysMin) {
        transitTimeArray.push(transitDaysMin.toString())
    }

    if (transitDaysMax) {
        transitTimeArray.push(transitDaysMax.toString())
    }

    if (
        (transitTimeArray.length === 1 && transitTimeArray[0] === '1') ||
        (transitTimeArray.length === 2 &&
            transitTimeArray[0] === '1' &&
            transitTimeArray[1] === '1')
    ) {
        return '1 day'
    }

    if (
        transitTimeArray.length === 2 &&
        transitTimeArray[0] === transitTimeArray[1]
    ) {
        return `${transitTimeArray[0]} days`
    }

    const formattedTransitTime = transitTimeArray.join('-')

    return formattedTransitTime && `${formattedTransitTime} days`
}

export const addBusinessDays = (
    date: string,
    businessDays: number,
): moment.Moment => {
    const newDate = moment(date).add(Math.floor(businessDays / 5) * 7, 'days')

    let remainingDays = businessDays % 5

    while (remainingDays > 0) {
        newDate.add(1, 'day')
        if (![ 0, 6 ].includes(newDate.day())) {
            remainingDays--
        }
    }

    return newDate
}

export const getNextBusinessDate = (
    fromDate: moment.Moment | null = null,
): moment.Moment => {
    if (!fromDate) {
        fromDate = moment().add(1, 'day')
    }

    if (fromDate.day() === 0) {
        fromDate.add(1, 'day')
    } else if (fromDate.day() === 6) {
        fromDate.add(2, 'day')
    }

    return fromDate
}

export const getElapsedBusinessDays = (
    fromDate: moment.Moment | null = null,
    toDate: moment.Moment,
): number => {
    if (!fromDate) {
        fromDate = moment().add(1, 'day')
    }

    const transitTime = toDate.diff(fromDate, 'days')

    let start = fromDate.day()

    let count = 0
    for (let i = 0; i < transitTime; i++) {
        if (start > 6){
            start = 0
        }
        if (start > 0 && start < 6){
            count++
        }

        start++
    }

    return count
}

export const getTransitDaysToDisplayFromEstimatedDeliveryDate = (
    translationEqual: string,
    translationSingle: string,
    translationPlural: string,
    pickupDate?: string | null,
    deliveryDate?: string | null,
    ) => {

    if (pickupDate === null) {
        pickupDate = undefined
    }
    if (!deliveryDate) {
        return 'N/A'
    }

    const momentPickupDate = moment(pickupDate)
    const momentdeliveryDate = moment(deliveryDate, 'ddd, MMM D, YYYY')
    const dayInMs = 24 * 60 * 60 * 1000

    const transitDays = Math.round(momentdeliveryDate.diff(momentPickupDate) / dayInMs)

    switch (transitDays) {
        case 0:
            return translationEqual
        case 1:
            return `${transitDays} ${translationSingle}`
        default:
            return `${transitDays} ${translationPlural}`
    }
}

export const getEstimatedDeliveryDateFromTransitTime = (
    pickupDate: string,
    transitDaysMin = 0,
    transitDaysMax = 0,
    format = 'ddd, MMM D, YYYY',
): string | null => {
    if (!transitDaysMax && !transitDaysMin) {
        return null
    }
    let averageDays = 0

    if (transitDaysMax && transitDaysMin) {
        averageDays = (transitDaysMax + transitDaysMin) / 2
    } else {
        if (transitDaysMin) {
            averageDays += transitDaysMin
        }

        if (transitDaysMax) {
            averageDays += transitDaysMax
        }
    }

    // sunday = 0, saturday = 6
    const pickupDayOfTheWeek = moment(pickupDate).day()
    const deliveryDayOfTheWeek = (pickupDayOfTheWeek + averageDays) % 7
    const weeks = Math.floor(averageDays / 7)

    let newDate
    let weekendDaysToAdd = 0

    if (deliveryDayOfTheWeek === 6 || deliveryDayOfTheWeek - pickupDayOfTheWeek < 0) {
        weekendDaysToAdd = 2
    }
    if (deliveryDayOfTheWeek === 0) {
        weekendDaysToAdd = 1
    }
    newDate = moment(pickupDate).add(averageDays + (weeks * 2) + weekendDaysToAdd, 'days')

    return getNextBusinessDate(newDate).format(format)
}

interface OrderInfoShipment {
    status: ShipmentStatus
    createdAt: string
    estimatedDeliveryDate: string
}
interface OrderInfoTrackingUpdate {
    id: string
    createdAt: string
    trackingUpdateTime: string
    status: TrackingUpdateStatus
    statusMessage: string
    city: string
    state: string
}
interface OrderListItemTrackingUpdate {
    id: string
    shipmentId: string
    trackingUpdateTime: string
    retrievalDateTime: string
    type: TrackingUpdateType
    status: TrackingUpdateStatus
    statusReason: TrackingUpdateStatusReason
    statusMessage: string
    exceptionSummary: TrackingUpdateExceptionSummary
    exceptionDetail: TrackingUpdateExceptionDetail
    stopType: TrackingUpdateStopType
    stopNumber: number
    apiStopId: string
    streetAddress: string
    city: string
    state: string
    country: string
    postalCode: string
    latitude: number
    longitude: number
    updatedAt: string
    createdAt: string
    createdByUserId: string
}
interface TrackingData{
    createdAt: string
    bookedAt: string
    shipment: OrderInfoShipment | null
    status: RfqStatus
    updates: OrderInfoTrackingUpdate[]
}
export const getEstimatedDeliveryDateFromTrackingUpdates = (trackingData?: OrderListItemTrackingUpdate[] | TrackingData | null): string | null => {
    let estimatedDeliveryDate = null

    if (trackingData && !isArray(trackingData) && Object.keys(trackingData).includes('updates')) {
        estimatedDeliveryDate = (trackingData as unknown as TrackingData)?.updates.find((trackingUpdate) => trackingUpdate.status === TrackingUpdateStatus.DELIVERED)?.trackingUpdateTime ?? null
    }
    else if (trackingData && (trackingData as OrderListItemTrackingUpdate[]).length && Object.keys((trackingData as OrderListItemTrackingUpdate[])[0]).includes('status')) {
        estimatedDeliveryDate = (trackingData as OrderListItemTrackingUpdate[])?.find((trackingUpdate) => trackingUpdate.status === TrackingUpdateStatus.DELIVERED)?.trackingUpdateTime ?? null
    }

    return estimatedDeliveryDate
}

export interface GetEstimatedDeliveryDateProps{
    pickupDate?: string | null
    transitDaysMin?: number | null
    transitDaysMax?: number | null
    format?: string,
    estimatedTimeOfArrival?: string | null,
    shipmentEstimatedDeliveryDate?: string | null,
    selectedQuoteEstimatedDeliveryDate?: string | null,
    trackingData?: OrderListItemTrackingUpdate[] | TrackingData | null
}

export const getEstimatedDeliveryDate: Function = ({
    pickupDate,
    transitDaysMin,
    transitDaysMax,
    format = 'ddd, MMM D, YYYY',
    estimatedTimeOfArrival,
    shipmentEstimatedDeliveryDate,
    selectedQuoteEstimatedDeliveryDate,
    trackingData,
}: GetEstimatedDeliveryDateProps): string | null => {
    let estimatedDeliveryDate = null

    if (shipmentEstimatedDeliveryDate) {
        estimatedDeliveryDate = shipmentEstimatedDeliveryDate
    }
    else if (estimatedTimeOfArrival) {
        estimatedDeliveryDate = estimatedTimeOfArrival
    }
        // FedEx may have a past date (at least has on their test API)
    else if (selectedQuoteEstimatedDeliveryDate && moment(selectedQuoteEstimatedDeliveryDate).isAfter(moment())) {
        estimatedDeliveryDate = selectedQuoteEstimatedDeliveryDate
    }
    else if (pickupDate && transitDaysMax && transitDaysMin) {
        estimatedDeliveryDate = getEstimatedDeliveryDateFromTransitTime(
            pickupDate,
            transitDaysMin,
            transitDaysMax,
            format,
        )
    }
    if (!estimatedDeliveryDate && trackingData) {
        estimatedDeliveryDate = getEstimatedDeliveryDateFromTrackingUpdates(trackingData)
    }

    return estimatedDeliveryDate ? moment(estimatedDeliveryDate).format(format) : null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deepCopy = (inObject: any): any => {
    let value
    let key

    if (typeof inObject !== 'object' || inObject === null) {
        return inObject
    }

    const outObject: Record<any, any> = Array.isArray(inObject) ? [] : {}

    for (key in inObject) {
        if (Object.prototype.hasOwnProperty.call(inObject, key)) {
            value = inObject[key]
            outObject[key] = deepCopy(value)
        }
    }

    return outObject
}

export const deepEqual: ((a: Record<string | number, any>, b: Record<string | number, any>, differenceFound?: boolean) => boolean) = (a, b, isEqual = true) => {
    const keys = Object.keys(a || {}) as string[] | number[]

    if (b === undefined) {
        isEqual = false
    }
    else {
        for (const key of keys) {
            if (typeof a[key] !== 'object' && a[key] !== b[key]) {
                isEqual = false
                break
            }
            if (typeof a[key] === 'object') {
                isEqual = deepEqual(a[key] as Record<string | number, any>, b[key] as Record<string | number, any>, isEqual)
                if (!isEqual) {
                    break
                }
            }
        }
    }

    return isEqual
}

export const formatZone = (
    city?: string,
    state?: string,
    country?: string,
): string => {
    const zones: string[] = []

    if (city) {
        zones.push(city)
    }
    if (state) {
        zones.push(state)
    }
    if (country) {
        zones.push(country)
    }

    return zones.join(', ')
}

export const checkIfNightlyHours = (open?: string, close?: string): boolean => {
    if (!open || !close) {
        return false
    }
    const openTime = moment(`${moment().format('YYYY-MM-DD')}T${open}`)
    const closeTime = moment(`${moment().format('YYYY-MM-DD')}T${close}`)

    return openTime.isAfter(closeTime)
}

export const formatPhoneNumber = (str: string): string => {
    let digits = String(str).replace(/^\+/g, '').replace(/\D/g, '').split('')
    let length = digits.length

    if (!length) {
        return ''
    }

    if (digits[0] !== '1') {
        digits.unshift('1')
        ++length
    }

    if (length > 17) {
        digits = digits.slice(0, 17)
    }

    if (length > 11) {
        digits.splice(11, 0, ' ext ')
    }

    if (length > 7) {
        digits.splice(7, 0, ' ')
    }

    if (length > 4) {
        digits.splice(4, 0, ' ')
    }

    if (length > 1) {
        digits.splice(1, 0, ' ')
    }

    digits.splice(0, 0, '+')

    return digits.join('')
}

export const formatRawPhoneNumber = (str: string | undefined): phoneAndExtension | null => {
    if (!str) {
        return null
    }

    // eslint-disable-next-line no-useless-escape
    const matches = /([\d-\(\)\s]+)([a-zA-Z:\.\s]+)?([\d]+)?/.exec(str)
    if (!matches) {
        return null
    }

    return {
        number: matches[1] ? matches[1].trim() : '',
        extension: matches[3] ? matches[3].trim() : '',
    }
}

export const toE164Standard = (str: string): string => {
    // This follows E.164 standard, but assumes a maximum of 10 digits for the local number part (North America) since there is no seperate
    // field for the extension to find the exact number of digits.
    let digits = String(str).replace(/^\+/g, '').replace(/\D/g, '').split('')
    const length = digits.length

    if (!length) {
        return ''
    }

    if (length > 17) {
        digits = digits.slice(0, 17)
    }

    if (length > 11) {
        digits.splice(11, 0, ';ext=')
    }

    digits.splice(0, 0, '+')

    return digits.join('')
}

export const sleep = (m: number): Promise<any> =>
    new Promise((r) => setTimeout(r, m))

export const nullIfEmpty = <T extends string | undefined | null> (value: T): T | null => {
    if (value === '') {
        return null
    }

    return value
}

export const sanitizeCompanyName = (name: string): string =>
    name.split(' ').map((part) => part[0] ? part[0].toUpperCase() + part.substr(1) : '').join(' ')

export const sanitizePersonName = (name: string): string =>
    name.split(' ').map((part) => part[0] ? part[0].toUpperCase() + part.substr(1).toLowerCase() : '').join(' ')

export const formatName = (firstName?: string | null, lastName?: string | null, middleName?: string | null): string => {
    const nameParts = []

    if (firstName) {
        nameParts.push(sanitizePersonName(firstName))
    }

    if (middleName) {
        nameParts.push(sanitizePersonName(middleName))
    }

    if (lastName) {
        nameParts.push(sanitizePersonName(lastName))
    }

    return nameParts.join(' ')
}

export type Volume = {
    volume: number
    volumeUnit: VolumeUnit
}

export const calculateVolume = (
    length: number,
    lengthUnit: DimensionUnit,
    width: number,
    widthUnit: DimensionUnit,
    height: number,
    heightUnit: DimensionUnit,
): Volume => {
    const units: DimensionUnit[] = [ lengthUnit, widthUnit, heightUnit ]
    const mostCommonUnit = units.reduce((a: DimensionUnit, b: DimensionUnit, i: number, arr: DimensionUnit[]) =>
        (arr.filter((v) => v === a).length >= arr.filter((v) => v === b).length ? a : b))

    if (lengthUnit !== mostCommonUnit) {
        length = convert(length).from(dimensionUnitMap.get(lengthUnit)).to(dimensionUnitMap.get(mostCommonUnit))
    }
    if (widthUnit !== mostCommonUnit) {
        width = convert(width).from(dimensionUnitMap.get(widthUnit)).to(dimensionUnitMap.get(mostCommonUnit))
    }
    if (heightUnit !== mostCommonUnit) {
        height = convert(height).from(dimensionUnitMap.get(heightUnit)).to(dimensionUnitMap.get(mostCommonUnit))
    }

    return {
        volume: mostCommonUnit === DimensionUnit.IN
            ? convert(length * width * height).from('in3').to('ft3')
            : convert(length * width * height).from('cm3').to('m3'),
        volumeUnit: mostCommonUnit === DimensionUnit.IN
            ? VolumeUnit.FT3
            : VolumeUnit.M3,
    }
}

export const calculateTotalVolume = (
    length: number,
    lengthUnit: DimensionUnit,
    width: number,
    widthUnit: DimensionUnit,
    height: number,
    heightUnit: DimensionUnit,
    quantity: number,
): Volume => {
    const { volume, volumeUnit } = calculateVolume(length, lengthUnit, width, widthUnit, height, heightUnit)

    return {
        volume: volume * quantity,
        volumeUnit,
    }
}

export type Density = {
    density: number
    densityUnit: DensityUnit
}

export const calculateDensity = (
    volume: number,
    volumeUnit: VolumeUnit,
    weight: number,
    weightUnit: WeightUnit,
): Density => {
    if (volumeUnit === VolumeUnit.FT3 && weightUnit !== WeightUnit.LB) {
        weight = convert(weight).from(weightUnitMap.get(weightUnit)).to(weightUnitMap.get(WeightUnit.LB))
    }

    if (volumeUnit === VolumeUnit.M3 && weightUnit !== WeightUnit.KG) {
        weight = convert(weight).from(weightUnitMap.get(weightUnit)).to(weightUnitMap.get(WeightUnit.KG))
    }

    return {
        density: weight / volume,
        densityUnit: volumeToDensityUnitMap.get(volumeUnit),
    }
}

export type Dimensions = {
    length: number
    lengthUnit: DimensionUnit
    width: number
    widthUnit: DimensionUnit
    height: number
    heightUnit: DimensionUnit
}

export const calculateDimensions = (
    quantity: number,
    totalVolume: number,
    totalVolumeUnit: VolumeUnit,
): Dimensions | null => {
    if (totalVolumeUnit !== VolumeUnit.FT3) {
        if (totalVolumeUnit === VolumeUnit.M3) {
            totalVolume = totalVolume * 35.315
        } else {
            return null
        }
    }

    const length = 48
    const width = 40
    const totalArea = (length / 12) * (width / 12) * quantity
    const height = Math.ceil((totalVolume / totalArea) * 12)

    return {
        length: length,
        lengthUnit: DimensionUnit.IN,
        width: width,
        widthUnit: DimensionUnit.IN,
        height: height,
        heightUnit: DimensionUnit.IN,
    }
}

export const calculateFreightClass = (
    density: number,
    densityUnit: DensityUnit,
): string | null => {
    let convertedDensity = density

    if (densityUnit === DensityUnit.KG_M3) {
        convertedDensity = convertedDensity / 16.0185
    } else if (densityUnit !== DensityUnit.LB_FT3) {
        return null
    }

    if (convertedDensity < 1) {
        return '500'
    }

    if ((convertedDensity >= 1) && (convertedDensity < 2)) {
        return '400'
    }

    if ((convertedDensity >= 2) && (convertedDensity < 3)) {
        return '300'
    }

    if ((convertedDensity >= 3) && (convertedDensity < 4)) {
        return '250'
    }

    if ((convertedDensity >= 4) && (convertedDensity < 5)) {
        return '200'
    }

    if ((convertedDensity >= 5) && (convertedDensity < 6)) {
        return '175'
    }

    if ((convertedDensity >= 6) && (convertedDensity < 7)) {
        return '150'
    }

    if ((convertedDensity >= 7) && (convertedDensity < 8)) {
        return '125'
    }

    if ((convertedDensity >= 8) && (convertedDensity < 9)) {
        return '110'
    }

    if ((convertedDensity >= 9) && (convertedDensity < 10.5)) {
        return '100'
    }

    if ((convertedDensity >= 10.5) && (convertedDensity < 12)) {
        return '92.5'
    }

    if ((convertedDensity >= 12) && (convertedDensity < 13.5)) {
        return '85'
    }

    if ((convertedDensity >= 13.5) && (convertedDensity < 15)) {
        return '77.5'
    }

    if ((convertedDensity >= 15) && (convertedDensity < 22.5)) {
        return '70'
    }

    if ((convertedDensity >= 22.5) && (convertedDensity < 30)) {
        return '65'
    }

    if ((convertedDensity >= 30) && (convertedDensity < 35)) {
        return '60'
    }

    if ((convertedDensity >= 35) && (convertedDensity < 50)) {
        return '55'
    }

    if (convertedDensity >= 50) {
        return '50'
    }

    return null
}

export const calculateFreightClassFromHandlingUnit = (
    handlingUnit: {
        quantity?: number | null
        length?: number | null
        lengthUnit?: DimensionUnit | null
        width?: number | null
        widthUnit?: DimensionUnit | null
        height?: number | null
        heightUnit?: DimensionUnit | null
        weight?: number | null
        weightUnit?: WeightUnit | null
    },
): string | null => {
    const roundedWeight = handlingUnit.weight ? round(handlingUnit.weight) : handlingUnit.weight

    const totalVolume = calculateTotalVolume(
        handlingUnit.length || 0,
        handlingUnit.lengthUnit || DimensionUnit.IN,
        handlingUnit.width || 0,
        handlingUnit.widthUnit || DimensionUnit.IN,
        handlingUnit.height || 0,
        handlingUnit.heightUnit || DimensionUnit.IN,
        handlingUnit.quantity || 0,
    )
    const density = calculateDensity(
        totalVolume.volume / (handlingUnit.quantity || 1),
        totalVolume.volumeUnit || VolumeUnit.FT3,
        roundedWeight || 0,
        handlingUnit.weightUnit || WeightUnit.LB,
    )

    return calculateFreightClass(density.density, density.densityUnit)
}

export type Dimension = {
    dimension: number
    dimensionUnit: DimensionUnit
}

export type Weight = {
    weight: number
    weightUnit: WeightUnit
}

export const convertDimensionToImperial = (
    dimension: number,
    dimensionUnit: DimensionUnit,
): Dimension => {
    if (dimensionUnit !== DimensionUnit.IN) {
        dimension = convert(dimension).from(dimensionUnitMap.get(dimensionUnit)).to(dimensionUnitMap.get(DimensionUnit.IN))
    }

    return {
        dimension,
        dimensionUnit: DimensionUnit.IN,
    }
}

export const convertWeightToImperial = (
    weight: number,
    weightUnit: WeightUnit,
): Weight => {
    if (weightUnit !== WeightUnit.LB) {
        weight = convert(weight).from(weightUnitMap.get(weightUnit)).to(weightUnitMap.get(WeightUnit.LB))
    }

    return {
        weight,
        weightUnit: WeightUnit.LB,
    }
}

export const convertWeightToSpecificUnit = (
    weight: number,
    currentWeightUnit: WeightUnit,
    resultUnit: WeightUnit,
): Weight => {
    if (currentWeightUnit === resultUnit){
        return {
            weight,
            weightUnit: currentWeightUnit,
        }
    }
    if (resultUnit === WeightUnit.KG) {
        return convertWeightToMetric(weight, currentWeightUnit)
    }
    if (resultUnit === WeightUnit.LB) {
        return convertWeightToImperial(weight, currentWeightUnit)
    }

    throw new Error('Couldn\'t weight convert units')
}

export const convertDimensionToSpecificUnit = (
    dimension: number,
    currentDimmentionUnit: DimensionUnit,
    resultUnit: DimensionUnit,
): Dimension => {
    if (currentDimmentionUnit === resultUnit){
        return {
            dimension,
            dimensionUnit: currentDimmentionUnit,
        }
    }
    if (resultUnit === DimensionUnit.CM) {
        return convertDimensionToMetric(dimension, currentDimmentionUnit)
    }
    if (resultUnit === DimensionUnit.IN) {
        return convertDimensionToImperial(dimension, currentDimmentionUnit)
    }

    throw new Error('Couldn\'t dimension convert units')
}

export const convertDimensionToMetric = (
    dimension: number,
    dimensionUnit: DimensionUnit,
): Dimension => {
    if (dimensionUnit !== DimensionUnit.CM) {
        dimension = convert(dimension).from(dimensionUnitMap.get(dimensionUnit)).to(dimensionUnitMap.get(DimensionUnit.CM))
    }

    return {
        dimension,
        dimensionUnit: DimensionUnit.CM,
    }
}

export const convertWeightToMetric = (
    weight: number,
    weightUnit: WeightUnit,
): Weight => {
    if (weightUnit !== WeightUnit.KG) {
        weight = convert(weight).from(weightUnitMap.get(weightUnit)).to(weightUnitMap.get(WeightUnit.KG))
    }

    return {
        weight,
        weightUnit: WeightUnit.KG,
    }
}

export const dimensionUnitMap = new Map()
dimensionUnitMap.set(DimensionUnit.CM, 'cm')
dimensionUnitMap.set(DimensionUnit.IN, 'in')

export const weightUnitMap = new Map()
weightUnitMap.set(WeightUnit.LB, 'lb')
weightUnitMap.set(WeightUnit.KG, 'kg')

const dimensionToVolumeUnitMap = new Map()
dimensionToVolumeUnitMap.set(DimensionUnit.CM, VolumeUnit.M3)
dimensionToVolumeUnitMap.set(DimensionUnit.IN, VolumeUnit.FT3)

const volumeToDensityUnitMap = new Map()
volumeToDensityUnitMap.set(VolumeUnit.M3, DensityUnit.KG_M3)
volumeToDensityUnitMap.set(VolumeUnit.FT3, DensityUnit.LB_FT3)

export const compareWeight = (weight1: number, weightUnit1: WeightUnit, weight2: number, weightUnit2: WeightUnit) => {
    weight2 = convert(weight2).from(weightUnitMap.get(weightUnit2)).to(weightUnitMap.get(weightUnit1))

    return  weight1 > weight2 ? -1 : weight2 > weight1 ? 1 : 0
}

export const compareDimension = (dim1: number, dimUnit1: DimensionUnit, dim2: number, dimUnit2: DimensionUnit) => {
    dim2 = convert(dim2).from(dimensionUnitMap.get(dimUnit2)).to(dimensionUnitMap.get(dimUnit1))

    return  dim1 > dim2 ? -1 : dim2 > dim1 ? 1 : 0
}

// eslint-disable-next-line no-useless-escape
export const coalesceJsonAgg = (jsonAgg: string): string => `coalesce(${jsonAgg}, \'[]\'::json)`

export const arrayUniqueValues = (array: any[]): any[] => [ ...new Set(array) ]

export type OrderClosingDetail = {
    latestTime: moment.Moment
    closingAt: moment.Moment
}

export const getOrderClosingTime = (orderOpenTime: string, lastEventTime: string | null): moment.Moment => {
    const latestTime = lastEventTime &&
    moment(lastEventTime).isAfter(orderOpenTime) ? moment.utc(lastEventTime) : moment.utc(orderOpenTime)

    const roundUp = latestTime.second() || latestTime.millisecond() ?
        latestTime.add(1, 'minute').startOf('minute') : latestTime.startOf('minute')

    return roundUp.add(30, 'minutes')
}

export type CurrencyExchangeRate = {
    id: string
    source: Currency
    target: Currency
    lazrRate: number
}

export const toCad = (amount: number, currencyExchangeRates: CurrencyExchangeRate[]): number => {
    if (!amount) {
        return 0
    }

    const rate = currencyExchangeRates.find(
        (currencyExchangeRate) => currencyExchangeRate.source === Currency.USD && currencyExchangeRate.target === Currency.CAD,
    )

    return rate ? rate.lazrRate * amount : amount
}

export const toUsd = (amount: number, currencyExchangeRates: CurrencyExchangeRate[]): number => {
    if (!amount) {
        return 0
    }

    const rate = currencyExchangeRates.find(
        (currencyExchangeRate) => currencyExchangeRate.source === Currency.CAD && currencyExchangeRate.target === Currency.USD,
    )

    return rate ? rate.lazrRate * amount : amount
}

export const round = (num: number, decimalPlaces = 2): number => {
    const multiplier = Math.pow(10, decimalPlaces)

    return Math.round(num * multiplier + Number.EPSILON) / multiplier
}

export const parseStreetNumberFromBasicAddress = (
    address: string,
): string =>
    address.split(' ')[0]

export const parseStreetNameFromBasicAddress = (
    address: string,
): string => {
    const split = address.split(' ')

    return split.slice(1, split.length).join(' ')
}

export const parseAreaCodeFromPhoneNumber = (
    phoneNumber: string,
): string =>
    phoneNumber.replace(/\D/g, '').substr(0, 3)

export const isCustomsInfoNeeded = (
    provider: Provider | undefined,
    transportType?: TransportType,
): boolean =>
    (isMailingService(transportType) || provider === Provider.FEDEX)


export const isDocumentsOnlyIndicator = (provider: Provider | undefined, documentsOnlyIndicator: boolean | null | undefined): boolean => {
    if (!provider || provider === Provider.CANADA_POST) {
        return false
    }

    return !!documentsOnlyIndicator
}

export const isHazmatFieldsRequired = (
    provider?: Provider,
    transportType?: TransportType,
): boolean =>
    isMailingService(transportType) && (provider === Provider.FEDEX || provider === Provider.PUROLATOR)

export const isMailingService = (transportType?: TransportType): boolean =>
    !!transportType && MAILING_SERVICES.includes(transportType)

export const isFreightService = (transportType?: TransportType): boolean =>
    !!transportType && FREIGHT_SERVICES.includes(transportType)

export const isCusma = (origin?: Country | null, destination?: Country | null) => {
    if (!origin || !destination) {
        return false
    }

    const cusma = [ Country.CA, Country.US, Country.MX ]

    return cusma.includes(origin) && cusma.includes(destination)
}

export const getStateMapping = (value : string): StateAndProvinces => value.toUpperCase() as StateAndProvinces

export const removeAccents = (word?: string): string => word ? word.trim().normalize('NFKD').replace(/[\u0300-\u036F]/g, '') : ''

export const compareAlphaNumericChars = (
    a?: string,
    b?: string,
    options?: {
        caseInsensitive: boolean
        accentInsensitive: boolean
    },
): boolean => {
    if (!a || !b) {
        return false
    }

    let str1 = a
    let str2 = b
    if (options?.caseInsensitive) {
        str1 = str1.toLowerCase()
        str2 = str2.toLowerCase()
    }
    if (options?.accentInsensitive) {
        str1 = removeAccents(str1)
        str2 = removeAccents(str2)
    }

    return str1 === str2
}

export const comparePostalCodes = (postalCode1?: string, postalCode2?: string): boolean => {
    if (!postalCode1 || !postalCode2) {
        return false
    }

    return compareAlphaNumericChars(
        postalCode1.split('-')[0],
        postalCode2.split('-')[0],
        { caseInsensitive: true, accentInsensitive: true },
    )
}

export const sanitize = <T> (input: T): T => {
    const mustSanitize = (k: string) => [
        'AWS_SECRET_ACCESS_KEY',
        'AWS_SESSION_TOKEN',
        'GOOGLE_API_KEY',
        'LAZR_AS2GATEWAY_PASSWORD',
        'LAZR_CANADA_POST_KEY',
        'LAZR_CANADA_POST_PROVIDER_BASIC_AUTH',
        'LAZR_CANPAR_PWD',
        'LAZR_DATABASE_CREDENTIALS_SECRET_PASSWORD',
        'LAZR_DAY_AND_ROSS_PASSWORD',
        'LAZR_EDI_RYDER_SFTP_PASSWORD',
        'LAZR_FEDEX_SECRET_KEY_LTL',
        'LAZR_FEDEX_SECRET_KEY',
        'LAZR_GLS_CANADA_AUTH',
        'LAZR_MANITOULIN_TOKEN',
        'LAZR_NATIONEX_API_KEY',
        'LAZR_NATIONEX_SECRET_KEY',
        'LAZR_P44_AUTH',
        'LAZR_PROD_DATABASE_READ_ENDPOINT_PASSWORD',
        'LAZR_PUROLATOR_PASSWORD',
        'LAZR_QUICKBOOKS_CLIENT_ID',
        'LAZR_QUICKBOOKS_CLIENT_SECRET',
        'LAZR_QUICKBOOKS_WEBHOOKS_NAME',
        'LAZR_RR_CLIENT_SECRET',
        'LAZR_RR_PASSWORD',
        'LAZR_SMTP_EMAIL_ADDRESS_AP_PASSWORD',
        'LAZR_SMTP_EMAIL_ADDRESS_AR_PASSWORD',
        'LAZR_SMTP_EMAIL_ADDRESS_INFO_PASSWORD',
        'LAZR_SMTP_PASSWORD',
        'LAZR_UPS_CLIENT_SECRET',
        'LAZR_UPS_PASSWORD',
        'LAZR_XERO_CAD_CLIENT_ID',
        'LAZR_XERO_CAD_CLIENT_SECRET',
        'LAZR_XERO_CAD_WEBHOOKS_NAME',
        'LAZR_XERO_USD_CLIENT_ID',
        'LAZR_XERO_USD_CLIENT_SECRET',
        'LAZR_XERO_USD_WEBHOOKS_NAME',
        'password',
    ].includes(k)

    const internal = <U>(value: U, key?: string | number): U => {
        try {
            if (typeof value === 'string') {
                if (mustSanitize(String(key))) {
                    return '*'.repeat(value.length) as typeof value
                }

                const parsed = JSON.parse(value)

                if (typeof parsed === 'object') {
                    return JSON.stringify(internal(parsed)) as typeof value
                }

                return value
            }

            if (typeof value === 'object' && value !== null) {
                const newObj: Record<any, any> = {}

                for (const k in value) {
                    // eslint-disable-next-line no-prototype-builtins
                    if ((value as Record<any, any>).hasOwnProperty(k)) {
                        newObj[k] = internal(value[k], k)
                    }
                }

                return newObj
            }

            return value
        } catch (e: any) {
            return value
        }
    }

    return internal(input)
}

export const findHighestChargeIndex =  (charges: {totalCad: number}[]): number => {
    let index = 0
    charges.forEach((charge, i) => {
        index = charge.totalCad >= charges[index].totalCad ? i : index
    })

    return index
}

export const findBillingChargeIndex = (charges: {code: string}[]): number => {
    const freightChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.FREIGHT)
    if (freightChargeIndex >= 0) {
        return freightChargeIndex
    }

    const itemChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.ITEM)
    if (itemChargeIndex >= 0) {
        return itemChargeIndex
    }

    const wghtChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.WGHT)
    if (wghtChargeIndex >= 0) {
        return wghtChargeIndex
    }

    const aswghtChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.ASWGHT)
    if (aswghtChargeIndex >= 0) {
        return aswghtChargeIndex
    }

    const dimwghtChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.DIMWGHT)
    if (dimwghtChargeIndex >= 0) {
        return dimwghtChargeIndex
    }

    const expediteChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.EXPEDITE)
    if (expediteChargeIndex >= 0) {
        return expediteChargeIndex
    }

    const nfcChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.NFC)
    if (nfcChargeIndex >= 0) {
        return nfcChargeIndex
    }

    const minChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.MIN)
    if (minChargeIndex >= 0) {
        return minChargeIndex
    }

    const delcChargeIndex = charges.findIndex((charge) => charge.code === BillingChargeType.DELC)
    if (delcChargeIndex >= 0) {
        return delcChargeIndex
    }

    return 0
}

export const findBillingChargeIndexes = (charges: {code: string}[], billingCharges: BillingChargeType[]): number[] => {
    let indexes: number[] = []

    for (const chargeType of billingCharges) {
        for (const [index, charge] of charges.entries()) {
            if (charge.code === chargeType) {
                indexes.push(index)
            }
        }
    }

    return indexes
}

export const isInternationalOrder = (
    originCountry?: Country | null,
    destinationCountry?: Country | null,
): boolean => !!originCountry && !!destinationCountry && originCountry !== destinationCountry

export const isUSToCanadaOrPR = (
    originCountry?: Country | null,
    destinationCountry?: Country | null,
): boolean => !!originCountry && !!destinationCountry && originCountry === Country.US &&
    ( destinationCountry === Country.CA || destinationCountry === Country.PR)

export const isUSToCanada = (
    originCountry?: Country | null,
    destinationCountry?: Country | null,
): boolean => !!originCountry && !!destinationCountry && originCountry === Country.US &&
    destinationCountry === Country.CA


export const isIntraUsOrder = (
    originCountry?: Country | null,
    destinationCountry?: Country | null,
): boolean => !!originCountry && !!destinationCountry && originCountry === destinationCountry && originCountry === Country.US

export const isIntraPrOrder = (
    originCountry?: Country | null,
    destinationCountry?: Country | null,
): boolean => !!originCountry && !!destinationCountry && originCountry === destinationCountry && originCountry === Country.PR

export const isIntraCaOrder = (
    originCountry?: Country | null,
    destinationCountry?: Country | null,
): boolean => !!originCountry && !!destinationCountry && originCountry === destinationCountry && originCountry === Country.CA

export const areFieldsSupportedByProvider = (
    provider: Provider | undefined,
    fieldNames: string[],
): boolean => {
    switch (provider) {
        case Provider.PUROLATOR:
            return fieldNames.every((val) => [
                'productCode',
                'productIndicatorFda',
                'productIndicatorTextile',
                'productIndicatorIsUsmca',
                'documentIndicatorUsmca',
                'documentIndicatorFda',
                'documentIndicatorFcc',
                'textileManufacturer',
                'senderIsProducerIndicator',
                'commercialInvoiceDocumentIndicator',
                'commercialInvoiceUserProvided',
                'importExportType',
            ].includes(val))
        case Provider.FEDEX:
            return fieldNames.every((val) =>
                [ 'customsBrokerStreetAddress', 'productIndicatorUsmcaType', 'dutyBillToAccountNumber', 'useThermalPrinter' ].includes(val))
        case Provider.CANADA_POST:
            return fieldNames.every((val) => [
                'useThermalPrinter',
                'reasonForExportType',
                'nonDeliveryHandlingType',
                'otherReasonForExport',
                'unitWeight',
            ].includes(val))
        case Provider.UPS:
            return fieldNames.every((val) =>
                [ 'dutyBillToAccountNumber' ].includes(val))
        case Provider.DHL:
            return fieldNames.every((val) =>
                [ 'incotermsType', 'useThermalPrinter' ].includes(val))
        default:
            break
    }

    return false
}

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K): Record<K, T[]> =>
    list.reduce((previous, currentItem) => {
        const group = getKey(currentItem)
        if (!previous[group]) {
            previous[group] = []
        }
        previous[group].push(currentItem)

        return previous
    }, {} as Record<K, T[]>)

export const BASE_TRANSACTION_ENVELOPE_COST = 0.25

export const LAZR_BASE_SHARE = 0.05

export const clientQuoteBaseTransactionFee = {
    [TransportType.ENVELOPE] : .25,
    [TransportType.PARCEL] : 5.00,
    [TransportType.PAK] : 5.00,
    [TransportType.EXPRESS_BOX] : 5.00,
    [TransportType.TUBE] : 5.00,
    [TransportType.FTL] : 12.00,
    [TransportType.LTL] : 12.00,
    [TransportType.OTHER] : 12.00,
}

export const lazrShareFunction = {
    [TransportType.ENVELOPE]: (amount: number): number => BASE_TRANSACTION_ENVELOPE_COST,
    [TransportType.PARCEL]: (amount: number): number => amount * LAZR_BASE_SHARE,
    [TransportType.PAK]: (amount: number): number => amount * LAZR_BASE_SHARE,
    [TransportType.EXPRESS_BOX]: (amount: number): number => amount * LAZR_BASE_SHARE,
    [TransportType.TUBE]: (amount: number): number => amount * LAZR_BASE_SHARE,
    [TransportType.FTL]: (amount: number): number => amount * LAZR_BASE_SHARE,
    [TransportType.LTL]: (amount: number): number => amount * LAZR_BASE_SHARE,
    [TransportType.OTHER]: (amount: number): number => amount * LAZR_BASE_SHARE,
}

export const calculateLazrShare = (
    sellingAmount: number,
    lazrShareFixedFeeCad: number,
    lazrShareFixedFeeUsd: number,
    lazrSharePercentage: number,
    currency: Currency,
): number => round(sellingAmount * lazrSharePercentage + (currency === Currency.CAD ? lazrShareFixedFeeCad : lazrShareFixedFeeUsd))

export const calculateInvoiceDueDate = (pickupDate: string| null,
    rfqOriginPickupDate: string,
    invoiceDate: string | null, isThreePl: boolean,
    isReceivable: boolean,
    paymentTermOverride?: number): string => {
    const currentPickupDate = pickupDate ? pickupDate : rfqOriginPickupDate
    if (isReceivable) {
        return  moment.max(moment(currentPickupDate).add(paymentTermOverride ? paymentTermOverride : 30, 'days'),
            moment().add(15, 'days')).format('YYYY-MM-DD')
    }

    if (isThreePl && invoiceDate ) {
        return calculateInvoiceDueDateWithTerms(invoiceDate, 60)
    }

    return  moment.max(moment(currentPickupDate).add(paymentTermOverride ? paymentTermOverride : 35, 'days'),
        moment().add(20, 'days')).format('YYYY-MM-DD')
}

export const calculateInvoiceDueDateWithTerms = (invoiceDate: string, paymentTermsDays: number): string =>
    moment(invoiceDate).add(paymentTermsDays, 'days').format('YYYY-MM-DD')

export const objectShallowEqual = (object1: Record<string, any>, object2: Record<string, any>): boolean => {
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        if (object1[key] !== object2[key]) {
            return false
        }
    }

    return true
}

export const isClientTariff = (tariffType?: TariffType | null): boolean => {
    if (!tariffType) {
        return false
    }

    return [ TariffType.CLIENT, TariffType.CLIENT_TARIFF_NO_TRANSPORT_COST, TariffType.COLLECT ]
        .includes(tariffType)
}

interface ShipmentIdentifier {
    source: ShipmentIdentifierSource
    type: ShipmentIdentifierType
    value: string
    primaryForType?: boolean | null
}

export const handleDuplicateShipmentIdentifierType = (shipmentIdentifiers: ShipmentIdentifier[]): ShipmentIdentifier[] => {
    const uniqShipmentIdentifiersByType = uniqBy(shipmentIdentifiers, 'type')

    const anyDuplicate = () =>
        uniqShipmentIdentifiersByType.length < shipmentIdentifiers.length

    const getDuplicateFreeData = (): ShipmentIdentifier[] => uniqShipmentIdentifiersByType
        .map((uniqShipmentIdentifier: ShipmentIdentifier): ShipmentIdentifier => {
            const tempShipmentIdentifiers = remove(shipmentIdentifiers, (tempShipmentIdentifier: ShipmentIdentifier) =>
                uniqShipmentIdentifier.type !== tempShipmentIdentifier.type,
            )

            // @ts-ignore
            return tempShipmentIdentifiers.length > 1 ?
                tempShipmentIdentifiers
                    .find((tempShipmentIdentifier: ShipmentIdentifier) => tempShipmentIdentifier.primaryForType) :
                uniqShipmentIdentifier
        })

    return !anyDuplicate() ?
        shipmentIdentifiers : getDuplicateFreeData()
}

export const isLazrTariff = (tariffType?: TariffType | null): boolean => {
    if (!tariffType) {
        return false
    }

    return [ TariffType.LAZR ].includes(tariffType)
}

export const convertJsonToXml = (data: Record<string, any>, builderOptions?: BuilderOptions): string => {
    const builder = new Builder(builderOptions || {
        xmldec: {
            version: '1.0',
            encoding: 'utf-8',
        },
    })
    const xmlString = builder.buildObject(data)

    return xmlString
}

export const convertXmlToJson = <T>(xmlString: string): Promise<T> =>
    parseXmlString(xmlString, { explicitArray: false, valueProcessors: [ processors.parseBooleans ] })

interface HandlingUnit{
    quantity: number
    length: number
    lengthUnit: DimensionUnit
    width: number
    widthUnit: DimensionUnit
}

export const calculateLinearFeet = (handlingUnit: HandlingUnit): number => {
    let width = convertDimensionToImperial(handlingUnit.width, handlingUnit.widthUnit).dimension
    let length = convertDimensionToImperial(handlingUnit.length, handlingUnit.lengthUnit).dimension
    if (width > length) {
        const tempWidth = width
        width = length
        length = tempWidth
    }

    if (width <= 48) {
        return length / 24
    }

    return length / 12
}

export const calculateTotalLinearFeet = (cargo: HandlingUnit[]): number =>
    cargo.reduce((totalLinearFeet, current) =>
        totalLinearFeet + (calculateLinearFeet(current) * current.quantity),
    0)

export const isEmptyString = (stringToCheck?: string | null | undefined): boolean => {
    if (!stringToCheck) {
        return true
    }

    if (stringToCheck.trim().length === 0) {
        return true
    }

    return false
}

export const cutStringToMaxLength = <T> (str: string | T, maxCharacterLength?: number | null): string | T => {
    if (!maxCharacterLength || !str) {
        return str
    }
    if (typeof str === 'string') {
        return str.substring(0, maxCharacterLength)
    }

    return str
}

export const booleanSum = (...fieldsToBeOred: boolean[]): boolean =>
    fieldsToBeOred.reduce((sum, current) => sum || current, false)

export const addDefaultAccessorials = <T extends Accessorial> (
    type: AddressType,
    addressLocationAccessorials: T[],
    locationAccessorials: T[],
    equipmentType?: EquipmentType | null,
): T[] => {
    const delAccessorialCode = type === AddressType.BUSINESS ? DOCK_DEL_ACCESSORIAL : RESIDENTIAL_DEL_ACCESSORIAL
    const puAccessorialCode = type === AddressType.BUSINESS ? DOCK_PU_ACCESSORIAL : RESIDENTIAL_PU_ACCESSORIAL
    const addressTypeAccessorial = locationAccessorials.find((accessorial) => (accessorial.accessorial.code === puAccessorialCode ||
            accessorial.accessorial.code === delAccessorialCode))

    const newAccessorials = [ ...addressLocationAccessorials ].filter((accessorial) =>
        !DEFAULT_ACCESSORIALS.includes(accessorial.accessorial.code))

    if (addressTypeAccessorial) {
        const hasLiftGateAccessorials = addressLocationAccessorials.some((accessorial) =>
            LIFTGATE_ACCESSORIALS.includes(accessorial.accessorial.code))
        if (type === AddressType.BUSINESS && !hasLiftGateAccessorials && equipmentType !== EquipmentType.FLATBED) {
            newAccessorials.push({ ...addressTypeAccessorial, isRemovable: true })
        }
        else if (type === AddressType.RESIDENTIAL) {
            newAccessorials.push({ ...addressTypeAccessorial, isRemovable: false })
        }
    }

    return newAccessorials
}

export const initializeAddressType = <T extends Accessorial> (accessorials: T[]) : AddressType =>
    accessorials.some((accessorial) => RESIDENTIAL_RELATED_ACCESSORIALS.includes(accessorial.accessorial.code)) ?
        AddressType.RESIDENTIAL : AddressType.BUSINESS

export const noLazrBolGenerationCarrierList = [
    'RDWY',
    'ECHO',
    'WWEX',
    'HMES',
    'XPOL',
    'EXLA',
] as const

export const noLazrLabelGenerationCarrierList = [
    'DAYR',
] as const

export const statusMappings = (status: InvoiceCsvStatus | null): string => {
    if (!status) {
        return ''
    }
    switch (status) {
        case InvoiceCsvStatus.PENDING:
            return 'Pending approval'
        default:
            return toSentenseCase(status)
    }
}

export const pendingPriceReviewtatusMappings = (status: PendingPriceReviewStatus | null): string => {
    if (!status) {
        return ''
    }

    return toSentenseCase(status)
}
export const missingAutomaticExcessiveLengthAccessorial =  (
    accessorialList: Accessorial[],
    length: number | null = 0,
    lengthUnit?: DimensionUnit | null,
): boolean => {
    const nonRemovableAccessorial = prepareAutomaticExcessiveLengthAccessorial(accessorialList, length, lengthUnit)

    return !nonRemovableAccessorial
}

export const missingAutomaticExcessiveHeightccessorial =  (
    accessorialList: Accessorial[],
    height: number | null = 0,
    heightUnit?: DimensionUnit | null,
): boolean => {
    const nonRemovableAccessorial = prepareAutomaticExcessiveHeightAccessorial(accessorialList, height, heightUnit)

    return !nonRemovableAccessorial
}

export const prepareAutomaticExcessiveLengthAccessorial =  (
    accessorialList: Accessorial[],
    length: number | null = 0,
    lengthUnit?: DimensionUnit | null,
): Accessorial | null => {
    if (!lengthUnit || !length) {
        return null
    }

    const excessiveFeetValue = getAutomaticExcessiveLengthInFeet(length, lengthUnit)
    if (!excessiveFeetValue) {
        return null
    }
    const code = `ELS_${excessiveFeetValue}`

    return accessorialList.find((quoteAccessorial) => quoteAccessorial.accessorial.code === code)
        ?? null
}

export const prepareAutomaticExcessiveHeightAccessorial = (
    accessorialList: Accessorial[],
    height: number | null = 0,
    heightUnit?: DimensionUnit | null,
):  Accessorial | null => {
    if ((!heightUnit || !height) || !isExcessiveHeightInInch(height, heightUnit)) {
        return null
    }

    const excessiveHeightAccessorialCode = 'EHS'

    return accessorialList.find((quoteAccessorial) => quoteAccessorial.accessorial.code === excessiveHeightAccessorialCode)
        ?? null
}
export const getAutomaticExcessiveLengthInFeet = (length: number, lengthUnit: DimensionUnit): number | null => {
    const correctUnitLength = convertDimensionToImperial(length, lengthUnit || DimensionUnit.IN).dimension

    if (correctUnitLength > 143) {
        let feet = Math.ceil(correctUnitLength / 12)
        feet = feet > 30 ? 30 : feet

        return feet
    }

    return null
}

export const isExcessiveHeightInInch = (height: number, heightUnit: DimensionUnit): boolean => {
    const correctUnitHeight = convertDimensionToImperial(height, heightUnit || DimensionUnit.IN).dimension

    return correctUnitHeight > 102
}
export const contentTypesMapping: Record<string, string> = {
//   File Extension   MIME Type
    abs:           'audio/x-mpeg',
    ai:            'application/postscript',
    aif:           'audio/x-aiff',
    aifc:          'audio/x-aiff',
    aiff:          'audio/x-aiff',
    aim:           'application/x-aim',
    art:           'image/x-jg',
    asf:           'video/x-ms-asf',
    asx:           'video/x-ms-asf',
    au:            'audio/basic',
    avi:           'video/x-msvideo',
    avx:           'video/x-rad-screenplay',
    bcpio:         'application/x-bcpio',
    bin:           'application/octet-stream',
    bmp:           'image/bmp',
    body:          'text/html',
    cdf:           'application/x-cdf',
    cer:           'application/pkix-cert',
    class:         'application/java',
    cpio:          'application/x-cpio',
    csh:           'application/x-csh',
    css:           'text/css',
    dib:           'image/bmp',
    doc:           'application/msword',
    dtd:           'application/xml-dtd',
    dv:            'video/x-dv',
    dvi:           'application/x-dvi',
    eot:           'application/vnd.ms-fontobject',
    eps:           'application/postscript',
    etx:           'text/x-setext',
    exe:           'application/octet-stream',
    gif:           'image/gif',
    gtar:          'application/x-gtar',
    gz:            'application/x-gzip',
    hdf:           'application/x-hdf',
    hqx:           'application/mac-binhex40',
    htc:           'text/x-component',
    htm:           'text/html',
    html:          'text/html',
    ief:           'image/ief',
    jad:           'text/vnd.sun.j2me.app-descriptor',
    jar:           'application/java-archive',
    java:          'text/x-java-source',
    jnlp:          'application/x-java-jnlp-file',
    jpe:           'image/jpeg',
    jpeg:          'image/jpeg',
    jpg:           'image/jpeg',
    js:            'application/javascript',
    jsf:           'text/plain',
    json:          'application/json',
    jspf:          'text/plain',
    kar:           'audio/midi',
    latex:         'application/x-latex',
    m3u:           'audio/x-mpegurl',
    mac:           'image/x-macpaint',
    man:           'text/troff',
    mathml:        'application/mathml+xml',
    me:            'text/troff',
    mid:           'audio/midi',
    midi:          'audio/midi',
    mif:           'application/x-mif',
    mov:           'video/quicktime',
    movie:         'video/x-sgi-movie',
    mp1:           'audio/mpeg',
    mp2:           'audio/mpeg',
    mp3:           'audio/mpeg',
    mp4:           'video/mp4',
    mpa:           'audio/mpeg',
    mpe:           'video/mpeg',
    mpeg:          'video/mpeg',
    mpega:         'audio/x-mpeg',
    mpg:           'video/mpeg',
    mpv2:          'video/mpeg2',
    ms:            'application/x-wais-source',
    nc:            'application/x-netcdf',
    oda:           'application/oda',
    odb:           'application/vnd.oasis.opendocument.database',
    odc:           'application/vnd.oasis.opendocument.chart',
    odf:           'application/vnd.oasis.opendocument.formula',
    odg:           'application/vnd.oasis.opendocument.graphics',
    odi:           'application/vnd.oasis.opendocument.image',
    odm:           'application/vnd.oasis.opendocument.text-master',
    odp:           'application/vnd.oasis.opendocument.presentation',
    ods:           'application/vnd.oasis.opendocument.spreadsheet',
    odt:           'application/vnd.oasis.opendocument.text',
    otg:           'application/vnd.oasis.opendocument.graphics-template',
    oth:           'application/vnd.oasis.opendocument.text-web',
    otp:           'application/vnd.oasis.opendocument.presentation-template',
    ots:           'application/vnd.oasis.opendocument.spreadsheet-template',
    ott:           'application/vnd.oasis.opendocument.text-template',
    ogx:           'application/ogg',
    ogv:           'video/ogg',
    oga:           'audio/ogg',
    ogg:           'audio/ogg',
    otf:           'application/x-font-opentype',
    spx:           'audio/ogg',
    flac:          'audio/flac',
    anx:           'application/annodex',
    axa:           'audio/annodex',
    axv:           'video/annodex',
    xspf:          'application/xspf+xml',
    pbm:           'image/x-portable-bitmap',
    pct:           'image/pict',
    pdf:           'application/pdf',
    pgm:           'image/x-portable-graymap',
    pic:           'image/pict',
    pict:          'image/pict',
    pls:           'audio/x-scpls',
    png:           'image/png',
    pnm:           'image/x-portable-anymap',
    pnt:           'image/x-macpaint',
    ppm:           'image/x-portable-pixmap',
    ppt:           'application/vnd.ms-powerpoint',
    pps:           'application/vnd.ms-powerpoint',
    ps:            'application/postscript',
    psd:           'image/vnd.adobe.photoshop',
    qt:            'video/quicktime',
    qti:           'image/x-quicktime',
    qtif:          'image/x-quicktime',
    ras:           'image/x-cmu-raster',
    rdf:           'application/rdf+xml',
    rgb:           'image/x-rgb',
    rm:            'application/vnd.rn-realmedia',
    roff:          'text/troff',
    rtf:           'application/rtf',
    rtx:           'text/richtext',
    sfnt:          'application/font-sfnt',
    sh:            'application/x-sh',
    shar:          'application/x-shar',
    sit:           'application/x-stuffit',
    snd:           'audio/basic',
    src:           'application/x-wais-source',
    sv4cpio:       'application/x-sv4cpio',
    sv4crc:        'application/x-sv4crc',
    svg:           'image/svg+xml',
    svgz:          'image/svg+xml',
    swf:           'application/x-shockwave-flash',
    t:             'text/troff',
    tar:           'application/x-tar',
    tcl:           'application/x-tcl',
    tex:           'application/x-tex',
    texi:          'application/x-texinfo',
    texinfo:       'application/x-texinfo',
    tif:           'image/tiff',
    tiff:          'image/tiff',
    tr:            'text/troff',
    tsv:           'text/tab-separated-values',
    ttf:           'application/x-font-ttf',
    txt:           'text/plain',
    ulw:           'audio/basic',
    ustar:         'application/x-ustar',
    vxml:          'application/voicexml+xml',
    xbm:           'image/x-xbitmap',
    xht:           'application/xhtml+xml',
    xhtml:         'application/xhtml+xml',
    xls:           'application/vnd.ms-excel',
    xml:           'application/xml',
    xpm:           'image/x-xpixmap',
    xsl:           'application/xml',
    xslt:          'application/xslt+xml',
    xul:           'application/vnd.mozilla.xul+xml',
    xwd:           'image/x-xwindowdump',
    vsd:           'application/vnd.visio',
    wav:           'audio/x-wav',
    wbmp:          'image/vnd.wap.wbmp',
    wml:           'text/vnd.wap.wml',
    wmlc:          'application/vnd.wap.wmlc',
    wmls:          'text/vnd.wap.wmlsc',
    wmlscriptc:    'application/vnd.wap.wmlscriptc',
    wmv:           'video/x-ms-wmv',
    woff:          'application/font-woff',
    woff2:         'application/font-woff2',
    wrl:           'model/vrml',
    wspolicy:      'application/wspolicy+xml',
    z:             'application/x-compress',
    zip:           'application/zip',
}

export type FilterProperties<T> = Pick<T, { [K in keyof T]: T[K] extends CallableFunction ? never : K }[keyof T]>

export const sortObjectByKey = <T extends Record<string, any>> (obj: T): T =>
    Object.fromEntries(
        Object
            .entries(obj)
            .sort(([ keyA ], [ keyB ]) => keyA.localeCompare(keyB)),
    ) as T

export const sortObjectBySubstringOfKey = <T extends Record<string, any>> (obj: T, start: number, end?: number): T =>
    Object.fromEntries(
        Object
            .entries(obj)
            .sort(([ keyA ], [ keyB ]) => keyA.slice(start, end).localeCompare(keyB.slice(start, end))),
    ) as T


/* NOTE: (GD) Changed Saturday to 6 and Sunday to 0 to match moment's documentation.
         If you're experiencing Timezone issues with EDI dates, please set it back this way:
            Saturday = 5
            Sunday = 6
*/
export const isSaturday = (date: string) =>
    moment.parseZone(date, 'YYYY-MM-DDTHH:mm:ssZ', true).utc().weekday() === 6

export const isSunday = (date: string) =>
    moment.parseZone(date, 'YYYY-MM-DDTHH:mm:ssZ', true).utc().weekday() === 0

export const getCountryIsoAlpha2 = (country: string): string | null => {
    if (!country) {
        return null
    }

    switch (country.length) {
        case 2:
            return country
        case 3:
            return isoCountries.alpha3ToAlpha2(country)
        default:
            return null
    }
}
export const coverageCommodityTypeMappings: Record<string, string> = {
    GENERAL: 'General dry and non-perishable goods',
    FRAGILE: 'Fragile articles',
    CONSUMER_ELECTRONICS: 'Consumer electronics',
    TEMPERATURE_CONTROLLED: 'Frozen foods/ Temperature-controlled cargo',
    LIGHT_MACHINERY: 'Light machinery',
    CARS: 'New/used cars',
}

const MAX_LENGTH_BY_PROVIDER = {
    [Provider.CANPAR]: 40,
    [Provider.CANADA_POST]: 44,
    [Provider.NATIONEX]: 50,
    [Provider.FEDEX]: 35,
    [Provider.UPS]: 35,
    [Provider.PUROLATOR]: 35,
    [Provider.GLS_CANADA]: 40,
}

export const cutCompanyNameByProviderLimit = (companyName: string, provider: Provider): string => {
    if (!companyName) {
        return companyName
    }
    // @ts-ignore
    const maxLength = MAX_LENGTH_BY_PROVIDER[provider]

    if (maxLength && isNumber(maxLength)) {
        return companyName.substring(0, maxLength)
    }

    return companyName
}
export const convertToUTC = (dateString: string, timeString: string, country: string, stateProvince: string): string => {
    const dateTimeString = `${dateString}T${timeString}`
    const region = `${country.toUpperCase()}-${stateProvince.toUpperCase()}`
    let timeZone = determineTimeZone(region)

    if (!timeZone) {
        console.error(`Could not determine timezone for region: ${region}, defaulting to MTL`)
        timeZone = 'America/Montreal'
    }

    const utcDateTime = mtz.tz(dateTimeString, timeZone).utc()

    return utcDateTime.format('YYYY-MM-DDTHH:mm:ss[Z]')
}
export const determineTimeZone = (region: string): string | null => {
    const regionMapping: Record<string, string> = {
        // Canadian Provinces
        'CA-AB': 'America/Edmonton',
        'CA-BC': 'America/Vancouver',
        'CA-MB': 'America/Winnipeg',
        'CA-NB': 'America/Moncton',
        'CA-NL': 'America/St_Johns',
        'CA-NS': 'America/Halifax',
        'CA-NT': 'America/Yellowknife',
        'CA-NU': 'America/Iqaluit',
        'CA-ON': 'America/Toronto',
        'CA-PE': 'America/Halifax',
        'CA-QC': 'America/Montreal',
        'CA-SK': 'America/Regina',
        'CA-YT': 'America/Whitehorse',

        // US States
        'US-AL': 'America/Chicago',
        'US-AK': 'America/Anchorage',
        'US-AZ': 'America/Phoenix',
        'US-AR': 'America/Chicago',
        'US-CA': 'America/Los_Angeles',
        'US-CO': 'America/Denver',
        'US-CT': 'America/New_York',
        'US-DE': 'America/New_York',
        'US-FL': 'America/New_York',
        'US-GA': 'America/New_York',
        'US-HI': 'Pacific/Honolulu',
        'US-ID': 'America/Denver',
        'US-IL': 'America/Chicago',
        'US-IN': 'America/Indianapolis',
        'US-IA': 'America/Chicago',
        'US-KS': 'America/Chicago',
        'US-KY': 'America/New_York',
        'US-LA': 'America/Chicago',
        'US-ME': 'America/New_York',
        'US-MD': 'America/New_York',
        'US-MA': 'America/New_York',
        'US-MI': 'America/Detroit',
        'US-MN': 'America/Chicago',
        'US-MS': 'America/Chicago',
        'US-MO': 'America/Chicago',
        'US-MT': 'America/Denver',
        'US-NE': 'America/Chicago',
        'US-NV': 'America/Los_Angeles',
        'US-NH': 'America/New_York',
        'US-NJ': 'America/New_York',
        'US-NM': 'America/Denver',
        'US-NY': 'America/New_York',
        'US-NC': 'America/New_York',
        'US-ND': 'America/Chicago',
        'US-OH': 'America/New_York',
        'US-OK': 'America/Chicago',
        'US-OR': 'America/Los_Angeles',
        'US-PA': 'America/New_York',
        'US-RI': 'America/New_York',
        'US-SC': 'America/New_York',
        'US-SD': 'America/Chicago',
        'US-TN': 'America/Chicago',
        'US-TX': 'America/Chicago',
        'US-UT': 'America/Denver',
        'US-VT': 'America/New_York',
        'US-VA': 'America/New_York',
        'US-WA': 'America/Los_Angeles',
        'US-WV': 'America/New_York',
        'US-WI': 'America/Chicago',
        'US-WY': 'America/Denver',

        // Mexico States
        'MX-AG': 'America/Mexico_City',
        'MX-BN': 'America/Tijuana',
        'MX-BS': 'America/Mazatlan',
        'MX-CI': 'America/Mexico_City',
        'MX-CH': 'America/Chihuahua',
        'MX-CL': 'America/Mexico_City',
        'MX-CP': 'America/Campeche',
        'MX-CS': 'America/Mexico_City',
        'MX-DF': 'America/Mexico_City',
        'MX-DG': 'America/Mazatlan',
        'MX-GE': 'America/Mexico_City',
        'MX-GJ': 'America/Mexico_City',
        'MX-HD': 'America/Mexico_City',
        'MX-JA': 'America/Mexico_City',
        'MX-MC': 'America/Mexico_City',
        'MX-MR': 'America/Mexico_City',
        'MX-MX': 'America/Mexico_City',
        'MX-NA': 'America/Mazatlan',
        'MX-NL': 'America/Monterrey',
        'MX-OA': 'America/Mexico_City',
        'MX-PU': 'America/Mexico_City',
        'MX-QE': 'America/Mexico_City',
        'MX-QI': 'America/Cancun',
        'MX-SI': 'America/Mazatlan',
        'MX-SL': 'America/Mexico_City',
        'MX-SO': 'America/Hermosillo',
        'MX-TA': 'America/Monterrey',
        'MX-TB': 'America/Mexico_City',
        'MX-TL': 'America/Mexico_City',
        'MX-VC': 'America/Mexico_City',
        'MX-YU': 'America/Merida',
        'MX-ZA': 'America/Mexico_City',
    };

    return regionMapping[region] || null
}

export const isCountryInCaUSPrVi = (country?: Country | null) => {
    if (!country) {
        return false
    }

    const caUsPrVi = [ Country.CA, Country.US, Country.PR, Country.VI ]

    return caUsPrVi.includes(country)
}

export const isCaUsPrViLane = (origin?: Country | null, destination?: Country | null) => {
    return isCountryInCaUSPrVi(origin) && isCountryInCaUSPrVi(destination)
}

export const checkIsEtdAlwaysEnabled = (provider: Provider): boolean => [ Provider.DHL ].includes(provider)

export const shipmentDocumentTypeMapping: Record<ShipmentDocumentType, string> = {
    CORRESPONDENCE_NO_CUSTOMS_VALUE: 'Correspondence/No customs value',
    ACCOUNTING_DOCUMENTS: 'Accounting documents',
    ANALYSIS_REPORTS: 'Analysis reports',
    APPLICATIONS: 'Applications (completed)',
    BANK_STATEMENTS: 'Bank statements',
    BID_QUOTATIONS: 'Bid quotations',
    BILLS_OF_SALE: 'Bills of sale',
    BIRTH_CERTIFICATES: 'Birth certificates',
    BONDS: 'Bonds',
    CHECKS: 'Checks (completed)',
    CLAIM_FILES: 'Claim files',
    CLOSING_STATEMENTS: 'Closing statements',
    CONFERENCE_REPORTS: 'Conference reports',
    CONTRACTS: 'Contracts',
    COST_ESTIMATES: 'Cost estimates',
    COURT_TRANSCRIPTS: 'Court transcripts',
    CREDIT_APPLICATIONS: 'Credit applications',
    DATA_SHEETS: 'Data sheets',
    DEEDS: 'Deeds',
    EMPLOYEMENT_PAPERS: 'Employement papers',
    ESCROW_INSTRUCTIONS: 'Escrow instructions',
    EXPORT_PAPERS: 'Export Papers',
    FINANCIAL_STATEMENT: 'Financial statement',
    IMMIGRATION_PAPERS: 'Immigration papers',
    INCOME_STATEMENTS: 'Income statements',
    INSURANCE_DOCUMENTS: 'Insurance documents',
    INTEROFFICE_MEMOS: 'Interoffice memos',
    INVENTORY_REPORTS: 'Inventory reports',
    INVOICES: 'Invoices (completed)',
    LEASES: 'Leases',
    LEGAL_DOCUMENTS: 'Legal documents',
    LETTER_OF_CREDIT_PACKETS: 'Letter of credit packets',
    LETTERS_AND_CARDS: 'Letters and cards',
    LOAN_DOCUMENTS: 'Loan documents',
    MARRIAGE_CERTIFICATES: 'Marriage certificates',
    MEDICAL_RECORDS: 'Medical records',
    OFFICE_RECORDS: 'Office records',
    OPERATING_AGREEMENTS: 'Operating agreements',
    PARENT_APPLICATIONS: 'Patent applications',
    PERMITS: 'Permits',
    PHOTOCOPIES: 'Photocopies',
    PROPOSALS: 'Proposals',
    PROSPECTUSES: 'Prospectuses',
    PURCHASE_ORDERS: 'Purchase orders',
    QUOTATIONS: 'Quotations',
    RESERVATION_CONFIRMATION: 'Reservation confirmation',
    RESUMES: 'Resumes',
    SALES_AGREEMENTS: 'Sales agreements',
    SALES_REPORTS: 'Sales reports',
    SHIPPING_DOCUMENTS: 'Shipping documents',
    STATEMENTS_REPORTS: 'Statements/Reports',
    STATISTICAL_DATA: 'Statistical data',
    STOCK_INFORMATION: 'Stock information',
    TAX_PAPERS: 'Tax Papers',
    TRADE_CONFIRMATION: 'Trade confirmation',
    TRANSCRIPTS: 'Transcripts',
    WARRANTY_DEEDS: 'Warranty deeds',
    OTHER: 'Others',
}

export const concatinateStrings = (args: (string | undefined | null)[],  delimiter: string): string | undefined => {
    const res: string[] = []
    args.forEach((arg)=> {
        if (arg) {
            res.push(arg)
        }
    })

    if (res.length > 0) {
        return res.join(delimiter)
    }

    return undefined
}

export const mergePDFs = async (pdfUint8Arrays: Uint8Array[]): Promise<Uint8Array>  => new Promise(async (resolve, reject) => {
    try {
        const mergedPdf = await PDFDocument.create()

        for (const pdfUint8Array of pdfUint8Arrays) {
            const pdfDoc = await PDFDocument.load(pdfUint8Array)
            const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices())
            pages.forEach((page) => mergedPdf.addPage(page))
        }

        resolve(mergedPdf.save())
    } catch (e) {
        console.error(e)
        reject(e)
    }
})

export const base64ToArrayBuffer = (base64: string): Uint8Array  => {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i)
    }

    return bytes
}

export const uint8ArrayToBase64 = (uint8Array: Uint8Array): string => {
    let binary = ''
    const len = uint8Array.byteLength;
    for (let i = 0; i< len; i++) {
        binary += String.fromCharCode(uint8Array[i])
    }
    return btoa(binary)
}

export const concatenateBase64Zpl = (base64ZplData: string[]): string => {
    const binaryStrings = base64ZplData.map((base64String) => atob(base64String))

    const decoder = new TextDecoder()
    const utf8Strings = binaryStrings.map((binaryString) => decoder.decode(new Uint8Array(binaryString.split('').map(c => c.charCodeAt(0)))))

    const combinedZpl = utf8Strings.reduce((acc, current) => acc + current, '')

    const encoder = new TextEncoder()
    const uint8Array = encoder.encode(combinedZpl)
    const base64Result = uint8ArrayToBase64(uint8Array)

    return base64Result
}

// used to determine the BillingChargeType.PAPERCOMMINV amount when no ETD
export const getPaperCommInvFee = (
    { currency, currencyRateFromCad, provider }: { currency: Currency | null, currencyRateFromCad: number, provider?: string }
): number => {
    if (!currency || !provider) {
        console.warn('getPaperCommInvFee utility is missing some parameters... Returning zero.')

        return 0
    }

    let cad
    switch (provider) {
        case Provider.UPS:
            cad = 1.35
            break;
        default:
            cad = 0
    }

    return currency === Currency.USD ? Math.round(cad * currencyRateFromCad * 100) / 100 : cad
}

export const getWrongAddressSurcharge = (
    { currency, currencyRateFromCad, provider }: { currency: Currency | null, currencyRateFromCad: number, provider?: string }
): number => {
    if (!currency || !provider) {
        console.warn('getWrongAddressSurcharge utility is missing some parameters... Returning zero.')

        return 0
    }

    let cad
    switch (provider) {
        case Provider.UPS:
            cad = 20
            break;
        default:
            cad = 0
    }

    return currency === Currency.USD ? Math.round(cad * currencyRateFromCad * 100) / 100 : cad
}

export const getMaxCoverageAmount = (
    { currency, currencyRateFromCad }: { currency: Currency | null, currencyRateFromCad: number }
): number => {

    const cad = 5000

    if (!currency || !currencyRateFromCad) {
        console.warn('getMaxCoverageAmount utility is missing some parameters... Returning CAD amount.')

        return cad
    }

    return currency === Currency.USD ? Math.round(cad * currencyRateFromCad * 100) / 100 : cad
}
